import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import ComponentTextblock3 from "../../components/component-textblock-3";
import Slider from "react-slick";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const ProductsNonMedicalDevice = ({ data, location }) => {
  const intl = useIntl();

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    //autoplay: true,
    autoplaySpeed: 4000,
    arrows: false,
    slidesToShow: 1,
    //centerPadding: '100px',
    focusOnSelect: true,
    //centerMode: true,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 896,
        settings: {
          centerMode: false,
        },
      },
    ],
  };

  return (
    <Layout location={location}>
      <Seo
        title={
          intl.locale === "en"
            ? `Innovative Cleaning Robot CL02 with desinfection`
            : `Innovativer Reinigungsroboter CL02 mit Desinfektionsfunktion`
        }
        description={
          intl.locale === "en"
            ? `Best innovative cleaning robot covers up to 33,000 square feet and coordinates with elevators using wireless network.`
            : `Innovativer Reinigungsroboter, der bis zu 3000m² Fläche abdeckt und über die Nutzung von WLAN, auch mit Aufzügen fahren kann.`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/3DC3oaoMrugTInZjMxMOIt/b5ea8f4ad00ea430cbb9a59d09499f14/E174.jpg?w=1200&h=630&fit=fill"
      />
      <section className="sect bg-hellblau position-relative">
        <StaticImage
          src="../../images/products/cleaning-robot/header-cleaning-robot@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-none bg-sm-relative"
          alt=""
        />
        <StaticImage
          src="../../images/products/cleaning-robot/header-lumbar-type-mobile@2x.png"
          className="sect_Product__catch___img2 bg-absolute d-sm-block bg-sm-relative"
          alt=""
        />

        <div className="container flex-row my-8">
          <div className="flex-column-6">
            <h1 className="h1">
              {intl.locale === "en"
                ? `Next generation Cleaning Robot with cutting edge technology`
                : `Reinigungsroboter der nächsten Generation mit Spitzentechnologie`}
            </h1>
            <p className="message-label-bold txt txt-rot">
              {intl.locale === "en"
                ? `Self-driving cleaning robot`
                : `Selbstfahrender Reinigungsroboter`}
            </p>
            <p>
              {intl.locale === "en"
                ? `This high speed and powerful vacuum robot is for large facilities
  such as shopping malls, conference centers, airports, railway
  stations, factories, storages, and hotels. One battery run covers
  up to 33,000 square feet (3,000m2).`
                : `Der kraftvolle Hochgeschwindigkeitsstaubsauger ist für große Gebäude wie Einkaufszentren, Kongresszentren, Bahnhöfe, Fabriken, Lagerhallen und Hotels gedacht. Eine Batterieladung reicht für bis zu 3000 m². `}
            </p>
          </div>
        </div>
      </section>

      <section className="sect p-0 bg-bls">
        <div className="container flex-row mt-9">
          <div className="flex-column-7 pr-4">
            <h2 className="fs-11">
              {intl.locale === "en"
                ? `Keeping large facilities such as hotels, shopping malls, and
  conference centers clean has never been easier.`
                : `Es war nie einfacher große Gebäude wie Hotels, Einkaufszentren und Kongresszentren sauber zu halten.`}
            </h2>
            <p className="mb-6">
              {intl.locale === "en"
                ? `The robot is capable of cleaning without the guidance of magnetic
  tapes or guide wires, as it can recognize and record an interior
  building form and cleaning routes at high accuracy. The cleaner
  could choose cleaning routes of CL02 by means of preliminary route
  setting with “Teaching Playback” function or automatic generation
  of cleaning paths. This enables it to efficiently prepare for
  cleaning of large areas. Each setting could be easily controlled
  with a tablet device.`
                : `Der Roboter ist in der Lage, ohne die Führung von Magnetbändern oder Führungsdrähten zu reinigen, da er die Form des Gebäudeinneren und die Reinigungsrouten mit hoher Genauigkeit erkennen und aufzeichnen kann. Das Reinigungspersonal kann die Reinigungsrouten von CL02 durch das Voreinstellen von Routen mit der Funktion ""Teaching Playback"" oder durch automatische Generierung von Reinigungspfaden wählen. So kann sich der Roboter effizient auf die Reinigung großer Flächen vorbereiten. Jede Einstellung kann mit einem Tablet leicht gesteuert werden.`}
            </p>
            <h3 className="txt txt-bla">
              {intl.locale === "en"
                ? `Extensive cleaning ability`
                : `Umfassende Reinigungsleistung`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `CL02, with its autonomous mobility at a very high speed and strong
  suction power, realizes extensive cleaning ability in comparison
  to the previous model developed by Cyberdyne. With one battery
  charge, the robot will cover the maximum of 3,000 m2.`
                : `Dank seiner autonomen Mobilität bei sehr hoher Geschwindigkeit und seiner starken Saugkraft erreicht der CL02 im Vergleich zum Vorgängermodell von Cyberdyne eine umfassende Reinigungsleistung und kann mit einer Batterieladung bis zu 3000 m2 abdecken.`}
            </p>
            <h3 className="txt txt-bla">
              {intl.locale === "en"
                ? `High level of safety with 3D obstacle detection`
                : `Hohe Sicherheit dank 3D-Hinderniserkennung`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `CL02 is installed with a specially designed 3D camera. The robot
  can detect obstacles in its pathway in 3 dimensions so that it can
  safely stop temporarily.`
                : `CL02 ist mit einer speziell entwickelten 3D-Kamera ausgestattet. Der Roboter kann Hindernisse auf seinem Weg in 3 Dimensionen erkennen, so dass er vorübergehend sicher anhalten kann.`}
            </p>
            <h3 className="txt txt-bla">
              {intl.locale === "en"
                ? `Realized contactless Disinfection workoption`
                : `Besitzt eine berührungslose Desinfektionseinstellung`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `As a countermeasure against infectious diseases, various
  disinfection feature was prepared as an option, such as sprayer to
  spray disinfection agent to handrails and benches, UV ray to
  disinfect virus on the floor and vacuuming virus left on floor
  after disinfection. All of the above disinfection work can be done
  without direct contact or intervention by the operator.`
                : `Als Maßnahme gegen Infektionskrankheiten wurden verschiedene Desinfektionsfunktionen als Option vorbereitet, so z.B. ein Sprüher zum Aufsprühen von Desinfektionsmitteln auf Handläufe und Bänke; UV-Strahlung zur Beseitigung von Viren auf dem Boden und das Absaugen von Viren, die nach der Desinfektion auf dem Boden verblieben sind. Alle oben genannten Desinfektionsmaßnahmen können ohne direkten Kontakt oder Eingreifen des Bedieners erfolgen.`}
            </p>
            <h3 className="txt txt-bla">
              {intl.locale === "en"
                ? `Rides on elevator`
                : `Fahrten mit dem Aufzug`}
            </h3>
            <p>
              {intl.locale === "en"
                ? `By setting up our Elevator Interface Unit, CL02 can coordinate
  with elevators using wireless network. Using this optional
  feature, the robot will ride on elevator on its own and continues
  its work across floors. Elevator Interface Unit is compatible with
  multiple elevator makers.`
                : `Mit unserer Aufzug-Schnittstelleneinheit kann CL02 via Drahtlosnetzwerk mit Aufzügen koordiniert werden. Dank dieser optionalen Funktion fährt der Roboter selbständig mit dem Aufzug und setzt seine Arbeit über die Etagen hinweg fort. Die Elevator Interface Unit ist mit Aufzügen mehrerer Aufzugshersteller kompatibel.`}
            </p>
          </div>
          <div className="flex-column-5">
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Vacuum-cleans floors autonomously`
                : `Staubsaugt Böden selbstständig`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Ability to cover large areas (up to 33,000 sq ft)`
                : `Deckt große Bereiche ab (bis zu 3000 m²)`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Avoids obstacles with advanced sensors`
                : `Weicht Hindernisse durch fortschrittliche Sensoren aus`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Applicable to both carpet and hard floor`
                : `Kann sowohl auf harten als auch weichen Böden angewendet werden`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Compact device width allows running through small pathways`
                : `Schmale Bauweise erlaubt das Befahren von schmalen Gängen`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Reports where it cleaned and the location of dust and debris on
  the map`
                : `Zeigt auf einer Karte an, wo gereinigt wurde und wo Staub und Ablagerung zu finden sind`}
            </p>
            <p className="fs-6 fsi pb-3 bb-1-white">
              {intl.locale === "en"
                ? `Cyberdyne’s skilled service-person helps set-up cleaning area in
  your facility`
                : `Ein qualifizierter Serviceitarbeiter von Cyberdyne hilft Ihnen die Reinigungsbereicher Ihrer Räumlichkeiten festzulegen `}
            </p>
          </div>
        </div>
      </section>

      <section className="sect">
        <div className="container">
          <h2 className="message-lg">
            {intl.locale === "en"
              ? `As the robot cleans, it creates a digital map of the building and
  diligently runs the entire designated area. Every time it completes
  a cleaning, it reports the area cleaned and locations of dust, dirt,
  and other debris.`
              : `Während der Roboter reinigt, erzeugt er eine digitale Karte des Gebäudes und fährt sorgfältig die zugewiesenen Areale ab. Nach jedem Reinigungsvorgang meldet er das gereinigte Areal und Bereiche mit Staub, Dreck und anderen Ablagerungen.`}
          </h2>
        </div>
      </section>

      <section className="bg-white">
        <div className="container">
          <div className="flex-row mx-n2 mb-6 pict-3">
            <div className="px-2">
              <StaticImage
                src="../../images/products/cleaning-robot/CL02_2009_2.png"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/products/cleaning-robot/CL02__2009.jpg"
                className="mb-4"
                alt=""
              />
            </div>
            <div className="px-2">
              <StaticImage
                src="../../images/products/cleaning-robot/CL02_elevator.JPG"
                className="mb-4"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en" ? `Video` : `Video`}
          </p>
          <p className="h3 font-weight-bold">
            {intl.locale === "en"
              ? `Watch the videos to see how CL02 effectively cleans and rides on an elevator`
              : `Schauen Sie sich die Videos an und lernen Sie wie CL02 sorgfältig reinigt und mit einem Aufzug fährt`}
          </p>
          <div className="slide">
            <Slider {...settings}>
              <div className="slide__link">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/jPaoXdd7FeA"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
                {/* <div className="w560">
                  <p className="message-label-bold">
                    {intl.locale === "en"
                      ? `HAL-therapy using the All-in-One Walker with a stroke patient`
                      : `HAL-Training im All-in-One Walker mit einem Schlaganfallpatienten`}
                  </p>
                </div> */}
              </div>
            </Slider>
          </div>
        </div>
      </section>

      <section className="p-0 py-120px position-relative">
        <StaticImage
          src="../../images/products/cleaning-robot/lumbar-type-spec-bg@2x.png"
          className="bg-absolute"
        />

        <div className="container">
          <div className="container__inner flex-row">
            <div className="flex-column-6">
              <h2>
                {intl.locale === "en" ? `Specifications` : `Spezifikationen`}
              </h2>
              <div className="flex-row mb-4">
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `External Dimensions`
                        : `Äußere Abmessungen`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Length 620mm × width 480mm × height 470mm`
                        : `Länge 620 mm × Breite 480 mm × Höhe 470 mm`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en" ? `Weight` : `Gewicht`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Approximately 63kg (including the battery weight)`
                        : `Ca. 63 kg (einschließlich des Gewichts der Batterie)`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Operating Time`
                        : `Betriebsdauer`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Approximately 2 hours`
                        : `Ca. 2 Stunden`}
                    </p>
                  </div>
                </div>
                <div className="flex-column-6">
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Coverage Areas`
                        : `Abgedeckte Bereiche`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Maximum of 3,000m2`
                        : `Maximal 3000 m2`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Dust Collecting Type`
                        : `Art der Staubabsaugung`}
                    </p>
                    <p className="">
                      {intl.locale === "en"
                        ? `Paper pack type`
                        : `Papierpackung`}
                    </p>
                  </div>
                  <div className="mb-2">
                    <p className="fwb mb-1">
                      {intl.locale === "en"
                        ? `Control Interface`
                        : `Steuerungs-Schnittstelle`}
                    </p>
                    <p className="">
                      {intl.locale === "en" ? `Tablet device` : `Tablet-Gerät`}
                    </p>
                  </div>
                </div>
              </div>
              <p>
                {intl.locale === "en"
                  ? `*Above figures may differ subject to options and settings.`
                  : `*Die oben genannten Zahlen können je nach Optionen und Einstellungen abweichen.`}
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="sect sect-qa bg-rot">
        <div className="container">
          <p className="h2 mb-5">
            {intl.locale === "en"
              ? `Learn more about our products`
              : `Erfahren Sie mehr über unsere Produkte`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  Motion Principle of HAL
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL can sensor faint BES that trickle onto the skin, process
  it, and simultaneously assist one’s desired movement with
  motors located on each hip and knee joint.`
                    : `HAL kann kleinste bioelektrische Signale auf der Haut erkennen, diese verarbeiten und gleichzeitig die beabsichtige Bewegung  durch Motoren an jedem Hüft- und Kniegelenk unterstützen. `}
                </p>
                <Link
                  to="/products/medical-device/hal-motion-principal/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lower Limb Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Think, Send, Read, Move—HAL is the World first neurologically
  controlled wearable Cyborg which puts your intentions into
  action. HAL improves locomotor activity in stroke, spinal cord
  injury and other neuromuscular disease patients.`
                    : `Denken, Senden, Lesen, Bewegen - HAL ist der weltweit erste neurologisch kontrollierte tragbare Cyborg, der Ihre Absichten in Aktionen umsetzt. HAL verbessert die Bewegungsfähigkeit nach Schlaganfällen, Rückenmarksverletzungen und anderen neuromuskulären Erkrankungen.`}
                </p>
                <Link
                  to="/products/medical-device/hal-limb/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Single Joint</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL Single Joint is a portable and lightweight unit which enables intensive and repetitive training of specific joints such as elbow, knee, and ankle.`
                    : `HAL Single Joint ist ein portables und leichtes Gerät, das ein intensives und wiederholtes Training eines speziellen Gelenkes, wie zum Beispiel Ellbogen, Knie und Sprunggelenk, ermöglicht.`}
                </p>
                <Link
                  to="/products/medical-device/hal-joint/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>HAL Lumbar Type</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Improving a quality of life by ensuring your well-being, reducing fatigue in the work environment, regaining your strength and mobility, and expanding your capability.`
                    : `Verbesserung der Lebensqualität durch Sicherstellung Ihres Wohlbefindens, Reduzierung der Ermüdung im Arbeitsumfeld, Wiedererlangen Ihrer Kraft und Mobilität, sowie Ausbau Ihrer Leistungsfähigkeit.`}
                </p>
                <Link
                  to="/products/non-medical-device/"
                  className="link-button"
                >
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            {/* <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cleaning Robot`
                    : `Reinigungsroboter`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Keeping large facilities such as airport, shopping malls,
  hotels, and conference centers clean has never been easier.
  Find out how our cleaning and disinfection robot keeps your
  space tidy without the need for human operation`
                    : `Es war nie einfacher große Gebäude wie Flughäfen, Einkaufszentren, Hotels und Konferenzzentren sauber zu halten. Finden Sie heraus wie unser Reinigungsroboter Ihre Räume ohne menschliche Unterstützung sauber hält.`}
                </p>
                <div>
                  <Link to="/products/cleaning-robot/" className="link-button">
                                      {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}

                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem> */}
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>CYIN</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Ingenious interfaces for people including those suffer from
  progressive neurodegenerative disease. CYIN utilizes
  biological information to help communicate with ease, despite
  all their limitations.`
                    : `Geniale Schnittstelle für Menschen, einschließlich derer die an progressiven neurodegenerativen Erkrankungen leiden. CYIN nutzt biologische Informationen um die Kommunikation, unabhängig all Ihrer Einschränkungen, zu vereinfachen.`}
                </p>
                <div>
                  <Link to="/products/communication/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>Acoustic X</AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Acoustic X is photoacoustic imaging technology that helps
  detect melanin, lipid, collages, water, and contrast agents
  specifically designed to locate various biomarkers.`
                    : `Acoustic X ist eine photoakustische Bildgebungstechnologie, die Melanin, Fette, Kollagen und Kontrastmittel zur Lokalisation bestimmter Biomarker, erkennt.`}
                </p>
                <div>
                  <Link to="/products/imaging/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_Product__footer p-0">
        <div className="container py-8 my-8 flex-row">
          <div className="flex-column-6">
            <h2 className="h3">
              {intl.locale === "en"
                ? `See How Cyberdyne Can Change Your Life`
                : `Erkennen Sie wie Cyberdyne Ihr Leben verändern kann`}
            </h2>
            <p>
              {intl.locale === "en"
                ? `Improving your own life-quality begins with a quick call or email
  to Cyberdyne`
                : `Die Verbesserung Ihrer Lebensqualität beginnt mit einem kurzen Anruf oder einer E-Mail an Cyberdyne`}
            </p>
            <div>
              <Link to="/contact/" className="link_button">
                {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
              </Link>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsNonMedicalDevice;
